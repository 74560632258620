<template>
  <div>
    <a-space>
      <a-button @click="handleShowModal" type="primary">选择教材</a-button>
      <a-button type="primary" danger @click="handleClean">清空</a-button>
    </a-space>
    <a-modal
      title="选择教材"
      v-if="visible"
      :visible="visible"
      @cancel="handleCancelModal"
      :maskClosable="false"
      :footer="null"
      :destroyOnClose="true"
    >
      <a-form
        ref="formRef"
        :model="formValue"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
      >
        <a-form-item
          name="book"
          label="教材"
          :rules="{ required: true, message: '请选择教材' }"
        >
          <a-select
            v-model:value="formValue.book"
            placeholder="请选择教材"
            show-search
            label-in-value
            :filter-option="false"
            :options="bookOptions"
            @search="handleSearchBook"
            @change="handleBookChange"
          >
            <template v-if="loading" #notFoundContent>
              <a-spin size="small" />
            </template>
          </a-select>
        </a-form-item>
        <a-spin :spinning="sectionLoading">
          <a-form-item
            name="book_section"
            label="章节"
            :rules="{ required: true, message: '请选择章节' }"
          >
            <a-tree-select
              labelInValue
              v-model:value="formValue.book_section"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              :tree-data="treeInfo"
              placeholder="请选择章节"
              @change="handleSectionChange"
            >
            </a-tree-select>
          </a-form-item>
        </a-spin>
        <a-form-item :wrapper-col="{ span: 14, offset: 11 }">
          <a-button type="primary" @click="handleSelectSubmit">确定</a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { computed, onMounted, ref, toRaw } from 'vue'
import {
  getOfficialBookSections,
  getOfficialList,
  getPersonalList,
  getPersonalBookSections
} from '@/services/teaching-material/index'
import { useRoute } from 'vue-router'
import { debounce } from '@/utils/common'
import { getTreeSelectInfo } from './common'
export default {
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          book_id: undefined,
          book_section_id: undefined
        }
      }
    },
    bookName: {
      type: String,
      default: () => {
        return undefined
      }
    },
    sectionName: {
      type: String,
      default: () => {
        return undefined
      }
    }
  },
  emits: ['onBookSelcetOk'],
  setup (props, { emit }) {
    const route = useRoute()
    // 可见性
    const visible = ref(false)
    // 教材名字
    const bookName = ref(props.bookName)
    const sectionName = ref(props.sectionName)

    // 初始信息
    const valueData = computed(() => props.value)

    const labelCol = { span: 5 }
    const wrapperCol = { span: 17 }
    // 教材的相关选项
    const bookOptions = ref([])
    // 章节目录结构
    const treeInfo = ref([])

    const loading = ref(false)
    const sectionLoading = ref(false)

    const formRef = ref()

    // 表单信息
    const formValue = ref({
      book: { value: valueData.value.book_id, label: bookName },
      book_section: {
        value: valueData.value.book_section_id === 0 ? undefined : valueData.value.book_section_id,
        label: sectionName
      },
      book_id: valueData.value.book_id,
      book_section_id: valueData.value.book_section_id
    })

    const resetData = () => {
      formValue.value = {
        book: { value: valueData.value.book_id, label: bookName },
        book_section: {
          value: valueData.value.book_section_id === 0 ? undefined : valueData.value.book_section_id,
          label: sectionName
        },
        book_id: valueData.value.book_id,
        book_section_id: valueData.value.book_section_id
      }
      bookName.value = props.bookName
      sectionName.value = props.sectionName
    }

    // 根据平台获取教材信息
    const getBookInfoFunctionByPlatform = () => {
      if (route.params.type === '1') {
        return getOfficialList
      } else if (route.params.type === '2') {
        return getPersonalList
      }
      return () => { console.log('no function') }
    }

    const getSectionFunctionByPlatform = () => {
      if (route.params.type === '1') {
        return getOfficialBookSections
      } else if (route.params.type === '2') {
        return getPersonalBookSections
      }
      return () => { console.log('no get sections function') }
    }

    const cleanBookSection = () => {
      formValue.value.book_section = undefined
      formValue.value.book_section_id = undefined
    }

    const handleClean = () => {
      formValue.value = {
        book: undefined,
        book_section: undefined,
        book_id: undefined,
        book_section_id: undefined
      }
      bookName.value = ''
      sectionName.value = ''
      const params = {
        book_id: formValue.value.book_id,
        book_section_id: formValue.value.book_section_id,
        bookName: toRaw(bookName.value),
        sectionName: toRaw(sectionName.value)
      }
      emit('onBookSelcetOk', params)
    }

    // 根据名字检索教材信息
    const handleSearchBook = debounce(async (name, callBack = undefined) => {
      loading.value = true
      // 根据平台选择接口方法
      const getBookInfoFunc = getBookInfoFunctionByPlatform()
      try {
        const { items } = await getBookInfoFunc({ name, per_page: 20 })
        if (items) {
          const list = items.map(item => {
            return {
              label: `${item.name}-${item.press_version?.name}`,
              value: item.id
            }
          })
          bookOptions.value = list
          loading.value = false
        }
        if (callBack) {
          callBack()
        }
      } catch (error) {
        loading.value = false
        console.log(error)
      }
    })

    // 根据选择的书本信息获取章节信息
    const handleGetSectionsInfo = async (bookId) => {
      const getSectionFunc = getSectionFunctionByPlatform()
      try {
        sectionLoading.value = true
        const list = await getSectionFunc(bookId)
        treeInfo.value = getTreeSelectInfo(list)
        sectionLoading.value = false
      } catch (error) {
        console.log(error)
        sectionLoading.value = false
      }
    }

    // 教材选择改变时
    const handleBookChange = (data) => {
      formValue.value.book_id = data.value
      bookName.value = data.label
      cleanBookSection()
      handleGetSectionsInfo(data.value)
    }

    // 章节选择改变时
    const handleSectionChange = (value) => {
      formValue.value.book_section_id = value.value
      sectionName.value = value.label
    }

    // 显示弹窗
    const handleShowModal = () => {
      visible.value = true
    }
    // 隐藏弹窗
    const handleCancelModal = () => {
      resetData()
      visible.value = false
    }

    // 选择完成
    const handleSelectSubmit = () => {
      formRef.value.validate().then(() => {
        console.log(formValue.value)
        const params = {
          book_id: formValue.value.book_id,
          book_section_id: formValue.value.book_section_id,
          bookName: toRaw(bookName.value),
          sectionName: toRaw(sectionName.value)
        }
        emit('onBookSelcetOk', params)
        handleCancelModal()
      })
    }

    // 初始化数据加载
    onMounted(() => {
      handleSearchBook('', async () => {
        const length = bookOptions.value.filter(item => item.label !== bookName.value).length
        if (length < 1) {
          const searchFunc = getBookInfoFunctionByPlatform()
          const { items } = await searchFunc(bookName.value)
          const data = items[0]
          if (data) {
            bookOptions.value.push({ value: data.id, label: data.name })
            console.log(bookOptions.value)
          }
        }
        if (formValue.value.book_id) {
          await handleGetSectionsInfo(formValue.value.book_id)
        }
      })
    })

    return {
      visible,
      treeInfo,
      labelCol,
      wrapperCol,
      formValue,
      loading,
      sectionLoading,
      bookOptions,
      formRef,
      handleShowModal,
      handleCancelModal,
      handleClean,
      handleSearchBook,
      handleBookChange,
      handleSelectSubmit,
      handleSectionChange
    }
  }
}
</script>

<style>
</style>
