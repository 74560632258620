<template>
  <a-card class="card_body" title="歌曲图片">
    <a-upload
      v-model:file-list="fileList"
      list-type="picture-card"
      accept=".png, .jpg, .jpeg"
      :customRequest="handleUpload"
      @preview="handlePreview"
      @change="handleChange"
    >
      <plus-outlined />
      <div class="ant-upload-text">上传图片</div>
    </a-upload>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </a-card>
</template>

<script>
import { computed, ref, toRaw } from 'vue'
import { PlusOutlined } from '@ant-design/icons-vue'
import { uploadFileToOss } from '@/utils/oss'
import { message } from 'ant-design-vue'

function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = () => resolve(reader.result)

    reader.onerror = error => reject(error)
  })
}

export default {
  emits: ['onChange'],
  components: {
    PlusOutlined
  },
  props: {
    form: {
      type: Object,
      default: () => { return {} }
    }
  },
  setup (props, { emit }) {
    const formRef = computed(() => props.form)
    const pictureUrl = formRef.value.picture_url || []
    const fileList = ref([])
    pictureUrl.forEach((item, index) => {
      fileList.value.push({
        uid: -1 * index,
        url: item,
        status: 'done'
      })
    })

    const errorList = computed(() => fileList.value.filter((item) => item.status === 'error'))

    const previewVisible = ref(false)
    const previewImage = ref('')

    const updataFormInfo = () => {
      const formInfo = {
        ...toRaw(formRef.value)
      }
      if (!formInfo.picture_url || formInfo.picture_url.length < 1) {
        formInfo.picture_url = []
      }
      const listInfo = fileList.value.map(i => toRaw(i))
      formInfo.picture_url = listInfo.map(item => item.url)
      emit('onChange', formInfo)
    }

    const handleUpload = ({ file }) => {
      try {
        uploadFileToOss(file).then(data => {
          const { url } = data
          fileList.value.forEach(item => {
            if (item.name === file.name) {
              item.url = url
              item.status = 'done'
            }
          })
          message.success('上传图片成功')
          updataFormInfo()
        })
      } catch (error) {
        console.log(error)
        message.error('上传图片失败，请重试')
      }
    }

    const handleCancel = () => {
      previewVisible.value = false
    }

    const handlePreview = async file => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      previewImage.value = file.url || file.preview
      previewVisible.value = true
    }

    const handleChange = (info) => {
      if (info.file.status === 'removed') {
        updataFormInfo()
      }
    }

    return {
      fileList,
      previewVisible,
      previewImage,
      errorList,
      handleUpload,
      handleCancel,
      handlePreview,
      handleChange
    }
  }
}
</script>

<style>
.card_body {
  margin-bottom: 20px;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
