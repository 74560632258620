<template>
  <a-card
    class="upload_card"
    hoverable
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <div class="main_body" v-if="hasSheet">
      <div v-if="isHover" class="upload_body">
        <a-upload
          class="upload_icon"
          accept=".evms"
          :before-upload="handleBeforeUpload"
        >
          <div class="icon">
            <UploadOutlined :style="{ color: store.state.themeColor }" />
          </div>
          <p class="text">上传evms文件</p>
        </a-upload>
      </div>
      <div v-else>
        <a-descriptions title="曲谱文件" bordered :column="3">
          <a-descriptions-item label="曲谱文件地址" :span="3">
            {{ formData.evms_file_url || "请上传evms文件" }}
          </a-descriptions-item>
          <a-descriptions-item label="曲谱文件MD5" :span="3">
            {{ formData.evms_file_md5 || "请上传evms文件" }}
          </a-descriptions-item>
          <a-descriptions-item label="曲谱文件大小" :span="3">
            {{ formData.evms_file_size || "请上传evms文件" }}
          </a-descriptions-item>
        </a-descriptions>
      </div>
    </div>
    <div v-else>
      <a-descriptions title="曲谱文件" bordered :column="3">
        <a-descriptions-item label="曲谱文件地址" :span="3">
          <a-tooltip>
            <template #title>{{ formData.evms_file_url }}</template>
            {{
              formData.evms_file_url.length > 40
                ? formData.evms_file_url.substr(0, 40) + "..."
                : formData.evms_file_url
            }}
          </a-tooltip>
        </a-descriptions-item>
        <a-descriptions-item label="曲谱文件MD5" :span="3">
          {{ formData.evms_file_md5 }}
        </a-descriptions-item>
        <a-descriptions-item label="曲谱文件大小" :span="3">
          <a-space>
            <span>{{ formData.evms_file_size }}</span>

            <a-upload
              :showUploadList="false"
              accept=".evms,.musicxml"
              :before-upload="handleBeforeUpload"
            >
              <UploadOutlined :style="{ color: store.state.themeColor }" />
              <a>重新选择</a>
            </a-upload>
            <a @click="showDeleteModal"><DeleteOutlined />清空</a>
          </a-space>
          <a-modal
            v-if="deleteVisible"
            title="警告"
            :visible="deleteVisible"
            :maskClosable="false"
            okText="确定"
            cancelText="取消"
            @ok="handleDeleteSheet"
            @cancel="handleDeleteCancel"
          >
            是否确认删除当前的evms曲谱文件？
          </a-modal>
        </a-descriptions-item>
      </a-descriptions>
    </div>
  </a-card>
</template>

<script lang="ts">
import { computed, ref, toRaw } from '@vue/reactivity'
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import { uploadFileToOss } from '@/utils/oss'
import { message } from 'ant-design-vue'
export default {
  emits: ['onChange'],
  components: {
    UploadOutlined,
    DeleteOutlined
  },
  props: {
    form: {
      type: Object,
      default: () => { return {} }
    }
  },
  setup (props, { emit }) {
    const formData = computed(() => props.form)
    console.log(formData)
    const store = useStore()
    const isHover = ref(false)
    const handleMouseEnter = () => {
      isHover.value = true
    }
    const hasSheet = computed(() => formData.value.evms_file_url === undefined || formData.value.evms_file_url === '')

    const deleteVisible = ref(false)

    const handleMouseLeave = () => {
      isHover.value = false
    }

    const showDeleteModal = () => {
      deleteVisible.value = true
    }

    const handleDeleteCancel = () => {
      deleteVisible.value = false
    }

    const handleDeleteSheet = () => {
      const formInfo = {
        ...toRaw(formData.value),
        evms_file_url: '',
        evms_file_size: undefined,
        evms_file_md5: ''
      }
      console.log(formInfo)
      emit('onChange', formInfo)
      handleDeleteCancel()
    }

    const uploadDataForm = (url, md5, size) => {
      console.log(url, md5, size)
      const formInfo = {
        ...toRaw(formData.value),
        evms_file_url: url,
        evms_file_size: size,
        evms_file_md5: md5
      }
      console.log(formInfo)
      emit('onChange', formInfo)
    }

    const handleBeforeUpload = file => {
      console.log('上传曲谱', file)
      try {
        uploadFileToOss(file, true).then((data: any) => {
          const { url, md5, size } = data
          uploadDataForm(url, md5, size)
          message.success('上传曲谱成功')
        })
      } catch (error) {
        console.log(error)
      }
      return false
    }

    return {
      formData,
      store,
      isHover,
      hasSheet,
      deleteVisible,
      handleMouseEnter,
      handleMouseLeave,
      handleBeforeUpload,
      handleDeleteSheet,
      showDeleteModal,
      handleDeleteCancel
    }
  }
}
</script>

<style lang="less">
.upload_card {
  width: 100%;
  padding: 0;
  margin-bottom: 10px;
}

.upload_body {
  padding-top: 50px;
  width: 100%;
  height: 100%;
}

.upload_icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.icon {
  font-size: 40px;
  text-align: center;
}
.text {
  color: #999;
}
.main_body {
  min-height: 210px;
}
</style>
