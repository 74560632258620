
import { computed, ref, toRaw } from '@vue/reactivity'
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import { uploadFileToOss } from '@/utils/oss'
import { message } from 'ant-design-vue'
export default {
  emits: ['onChange'],
  components: {
    UploadOutlined,
    DeleteOutlined
  },
  props: {
    form: {
      type: Object,
      default: () => { return {} }
    }
  },
  setup (props, { emit }) {
    const formData = computed(() => props.form)
    console.log(formData)
    const store = useStore()
    const isHover = ref(false)
    const handleMouseEnter = () => {
      isHover.value = true
    }
    const hasSheet = computed(() => formData.value.evms_file_url === undefined || formData.value.evms_file_url === '')

    const deleteVisible = ref(false)

    const handleMouseLeave = () => {
      isHover.value = false
    }

    const showDeleteModal = () => {
      deleteVisible.value = true
    }

    const handleDeleteCancel = () => {
      deleteVisible.value = false
    }

    const handleDeleteSheet = () => {
      const formInfo = {
        ...toRaw(formData.value),
        evms_file_url: '',
        evms_file_size: undefined,
        evms_file_md5: ''
      }
      console.log(formInfo)
      emit('onChange', formInfo)
      handleDeleteCancel()
    }

    const uploadDataForm = (url, md5, size) => {
      console.log(url, md5, size)
      const formInfo = {
        ...toRaw(formData.value),
        evms_file_url: url,
        evms_file_size: size,
        evms_file_md5: md5
      }
      console.log(formInfo)
      emit('onChange', formInfo)
    }

    const handleBeforeUpload = file => {
      console.log('上传曲谱', file)
      try {
        uploadFileToOss(file, true).then((data: any) => {
          const { url, md5, size } = data
          uploadDataForm(url, md5, size)
          message.success('上传曲谱成功')
        })
      } catch (error) {
        console.log(error)
      }
      return false
    }

    return {
      formData,
      store,
      isHover,
      hasSheet,
      deleteVisible,
      handleMouseEnter,
      handleMouseLeave,
      handleBeforeUpload,
      handleDeleteSheet,
      showDeleteModal,
      handleDeleteCancel
    }
  }
}
