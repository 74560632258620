// 表单类型
export const FormActionType = {
  CREATE: 'create',
  EDIT: 'edit'
}

export const SheetSongTypeList = [
  { value: 0, label: '演唱歌曲' },
  { value: 2, label: '合唱歌曲' },
  { value: 3, label: '多声部练习歌曲' },
  { value: 4, label: '钢琴歌曲' }
]

// 曲谱类型
export const SheetTypeList = [
  { value: 0, label: '简谱' },
  { value: 1, label: '五线谱' },
  { value: 2, label: '简线同谱' },
  { value: 3, label: '暂无曲谱' }
]

export const TreeItemType = {
  Chapter: 0,
  Periods: 1
}
