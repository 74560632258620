<template>
  <div>
    <a-row>
      <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="16">
        <a-form
          ref="formRef"
          :model="formData"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-form-item
            name="name"
            label="歌曲名称"
            :rules="[{ required: true, message: '请输入歌曲名称' }]"
          >
            <a-input v-model:value="formData.name" />
          </a-form-item>
          <a-form-item
            name="song_code"
            label="歌曲识别码"
            :rules="[{ required: true, validator: codeLengthValidate }]"
            v-if="route.params.type === '1'"
          >
            <a-input v-model:value="formData.song_code" :disabled="isEdit">
              <template #suffix>
                <a-tooltip
                  title="存在相同的歌曲识别码"
                  v-if="hasSameCode"
                  default-visible
                >
                  <info-circle-outlined style="color: rgb(233, 103, 33)" />
                </a-tooltip>
              </template>
            </a-input>
          </a-form-item>
          <a-form-item
            name="py"
            label="歌曲简拼"
            :rules="[{ required: true, message: '请输入歌曲简拼' }]"
          >
            <a-input v-model:value="formData.py" />
          </a-form-item>
          <a-form-item
            name="song_type"
            label="歌曲类型"
            :rules="[{ required: true, message: '请选择歌曲类型' }]"
          >
            <a-select
              v-model:value="formData.song_type"
              :options="SheetSongTypeList"
            />
          </a-form-item>
          <a-form-item
            name="sheet_type"
            label="谱例类型"
            :rules="[{ required: true, message: '请选择谱例类型' }]"
          >
            <a-select
              v-model:value="formData.sheet_type"
              :options="SheetTypeList"
            />
          </a-form-item>
          <a-form-item label="标签">
            <a-select
              style="width: 95%"
              v-model:value="tagList"
              :options="tagOpt"
              mode="multiple"
              @search="handleSerach"
            >
            </a-select>
            <SearchOutlined class="serach_icon" />
          </a-form-item>
          <a-form-item
            name="speed"
            label="歌曲速度"
            :rules="[{ required: true, message: '请输入歌曲速度' }]"
          >
            <a-input-number
              style="width:100%"
              v-model:value="formData.speed"
              :min="0"
              :step="0.1"
            ></a-input-number>
          </a-form-item>
          <a-form-item name="ori_file" label="原唱文件">
            <upload-song
              type="ori"
              :formData="formData"
              @onChange="handleUploadFormChange"
            ></upload-song>
          </a-form-item>
          <a-form-item name="ori_file_url" label="原唱文件地址">
            <a-input
              v-model:value="formData.ori_file_url"
              :disabled="true"
            ></a-input>
          </a-form-item>
          <a-form-item name="ori_file_size" label="原唱文件大小">
            <a-input
              v-model:value="formData.ori_file_size"
              :disabled="true"
            ></a-input>
          </a-form-item>
          <a-form-item name="ori_file_md5" label="原唱文件MD5">
            <a-input
              v-model:value="formData.ori_file_md5"
              :disabled="true"
            ></a-input>
          </a-form-item>
          <a-form-item name="acc_file" label="伴唱文件">
            <upload-song
              type="acc"
              :formData="formData"
              @onChange="handleUploadFormChange"
            ></upload-song>
          </a-form-item>
          <a-form-item name="acc_file_url" label="伴唱文件地址">
            <a-input
              v-model:value="formData.acc_file_url"
              :disabled="true"
            ></a-input>
          </a-form-item>
          <a-form-item name="acc_file_size" label="伴唱文件大小">
            <a-input
              v-model:value="formData.acc_file_size"
              :disabled="true"
            ></a-input>
          </a-form-item>
          <a-form-item name="acc_file_md5" label="伴唱文件MD5">
            <a-input
              v-model:value="formData.acc_file_md5"
              :disabled="true"
            ></a-input>
          </a-form-item>
          <a-form-item
            name="acc_speed"
            label="伴奏速度"
            :rules="[{ required: true, validator: accSongSpeedValidate }]"
          >
            <a-input-number
              v-model:value="formData.acc_speed"
              :step="0.1"
              style="width: 100%"
              :min="0"
            ></a-input-number>
          </a-form-item>
          <a-form-item name="book_info" label="教材选择">
            <select-book
              :value="bookInfo"
              :bookName="bookName"
              :sectionName="sectionName"
              @onBookSelcetOk="handleSelectBook"
            ></select-book>
          </a-form-item>
          <a-form-item label="教材名称/章节">
            <a-input :disabled="true" v-model:value="bookNameInput"></a-input>
          </a-form-item>
          <a-form-item label="分声部范唱音频">
            <UploadMultiVoice
              v-if="true"
              :formData="formData"
              @onChange="handleUploadFormChange"/>
          </a-form-item>
        </a-form>
      </a-col>
      <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="8">
        <upload-poster
          ref="uploadPosterRef"
          :form="formData"
          @onChange="handleUploadFormChange"
        ></upload-poster>
        <upload-sheet
          :form="formData"
          @onChange="handleUploadFormChange"
        ></upload-sheet>
        <upload-evms-sheet
          :form="formData"
          @onChange="handleUploadFormChange"
        ></upload-evms-sheet>
      </a-col>
      <a-col :offset="11" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <a-button type="primary" @click="onSubmit">保存</a-button>
      </a-col>
    </a-row>
  </div>
</template>

<script lang="ts">
import { computed, onBeforeMount, reactive, ref, toRaw, watch } from 'vue'
import { useRoute } from 'vue-router'
import { FormActionType, SheetSongTypeList, SheetTypeList } from './config'
import UploadSong from './UploadSong.vue'
import UploadSheet from './UploadSheet.vue'
import UploadPoster from './UploadPoster.vue'
import UploadEvmsSheet from './UploadEvmsSheet.vue'
import UploadMultiVoice from './UploadMultiVoice.vue'
import SelectBook from './SelectBook.vue'
import { debounce } from '@/utils/common'
import * as service from '@/services/songs'
import {
  SearchOutlined,
  InfoCircleOutlined
} from '@ant-design/icons-vue'
export default {
  emits: ['onOk'],
  components: { UploadSong, UploadSheet, UploadPoster, SelectBook, SearchOutlined, InfoCircleOutlined, UploadEvmsSheet, UploadMultiVoice },
  props: {
    actionType: {
      type: String,
      default: () => { return FormActionType.CREATE }
    },
    // 如果是编辑，那么current代表的是当前选中的歌曲的信息
    current: {
      type: Object,
      default: () => { return {} }
    }
  },
  setup (props, { emit }) {
    const route = useRoute()
    // 是否存在相同code
    const hasSameCode = ref(false)
    const formRef = ref()
    const currentValue = computed(() => (props.current))
    const isEdit = computed(() => props.actionType === FormActionType.EDIT)
    const uploadPosterRef = ref()

    const formData = ref({
      name: '',
      song_code: '',
      py: '',
      song_type: undefined,
      sheet_type: undefined,
      speed: undefined,
      picture_url: [],
      evxml_file_url: '',
      evxml_file_md5: '',
      evxml_file_size: undefined,
      evms_file_url: '',
      evms_file_md5: '',
      evms_file_size: undefined,
      ori_file_url: '',
      ori_file_md5: '',
      ori_file_size: undefined,
      acc_file_url: '',
      acc_file_md5: '',
      acc_file_size: undefined,
      acc_speed: undefined,
      book_id: undefined,
      book_section_id: undefined,
      extend_data: {
        multi_part_song: []
      },
      ...toRaw(currentValue.value)
    })

    const labelCol = { span: 5 }
    const wrapperCol = { span: 17 }

    const bookName = ref(currentValue.value.bookName || '')
    const sectionName = ref(currentValue.value.sectionName || '')
    const bookNameInput = computed(() => `${bookName.value ? bookName.value + '/' : ''}${sectionName.value}`)

    const bookInfo = ref({
      book_id: currentValue.value?.book_id,
      book_section_id: currentValue.value?.book_section_id
    })

    const tagList = ref(currentValue.value?.tags || [])
    const tagOpt = ref([])

    const getTags = async name => {
      const getTagsInfo = route.params.type === '1' ? service.getOfficialTag : service.getPersonalTag
      const data = await getTagsInfo({ keyword: name, per_page: 10, page: 1 })
      if (!data.items || data.items.length < 1) {
        data.items = [{ tag: name }]
      }
      return data
    }

    const handleSerach = debounce(async value => {
      const data = await getTags(value)
      tagOpt.value = data.items.map(item => { return { label: item.tag, value: item.tag } })
    })

    onBeforeMount(async () => {
      await handleSerach('')
    })

    const codeLengthValidate = (rule, value) => {
      if (!value) {
        Promise.reject(new Error('请输入歌曲识别码'))
      }
      if (value.length !== 7 && route.params.type === '1') {
        return Promise.reject(new Error('请输入7位歌曲识别码'))
      }
      return Promise.resolve()
    }

    const accSongSpeedValidate = (rule, value) => {
      if (formData.value.acc_file_url && formData.value.acc_file_url.length > 0) {
        if (!value) {
          return Promise.reject(new Error('伴唱歌曲存在时必须填写伴唱速度'))
        }
      }
      return Promise.resolve()
    }

    const handleSelectBook = (params) => {
      formData.value = {
        ...toRaw(formData.value),
        book_id: params.book_id,
        book_section_id: params.book_section_id
      }
      bookName.value = params.bookName
      sectionName.value = params.sectionName
    }

    const onSubmit = () => {
      formRef.value.validate().then(() => {
        const params = formData.value
        if (params.book_id === null || params.book_id === undefined || params.book_section_id === null || params.book_section_id === undefined) {
          params.book_id = 0
          params.book_section_id = 0
        }
        emit('onOk', params, tagList.value)
      })
    }

    const handleUploadFormChange = info => {
      console.log('upload ====> ', info)
      formData.value = info
    }
    const checkSameCode = debounce(async () => {
      const { exist } = await service.checkSheetSongCode({ song_code: formData.value.song_code })
      hasSameCode.value = exist
    })

    watch(() => formData.value.song_code, (v) => {
      if (v.length === 7 && route.params.type === '1') {
        checkSameCode()
      } else {
        hasSameCode.value = false
      }
    })

    return {
      route,
      formRef,
      labelCol,
      wrapperCol,
      formData,
      SheetSongTypeList,
      SheetTypeList,
      uploadPosterRef,
      bookName,
      bookNameInput,
      sectionName,
      isEdit,
      bookInfo,
      tagList,
      tagOpt,
      hasSameCode,
      handleSerach,
      onSubmit,
      handleSelectBook,
      handleUploadFormChange,
      codeLengthValidate,
      accSongSpeedValidate
    }
  }
}
</script>

<style>
.serach_icon {
  margin-left: 10px;
  color: #f5f5f5;
}
</style>
<style scoped>
.multi-voice-upload-btn {
  margin-right: 15px;
}
.red-text {
  color: red;
}
</style>
