<template>
  <a-spin :spinning="initLoading">
    <a-card v-if="!initLoading" title="编辑谱例歌曲">
      <template #extra><a @click="handleBack">返回</a></template>
      <sheet-song-base-form
        :actionType="FormActionType.EDIT"
        :current="current"
        @onOk="handleCreate"
      ></sheet-song-base-form>
    </a-card>
  </a-spin>
</template>

<script>
import SheetSongBaseForm from './SheetSongBaseForm.vue'
import { useRouter, useRoute } from 'vue-router'
import {
  editOfficialSheetSong,
  getOfficialSheetSongInfo,
  setOfficialSheetSongTags,
  editPersonalSheetSong,
  getPersonalSheetSongInfo,
  setPersonalSheetSongTags
} from '@/services/songs'
import { message } from 'ant-design-vue'
import { FormActionType } from './config'
import { onMounted, ref } from 'vue'
export default {
  components: { SheetSongBaseForm },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const current = ref({})
    const initLoading = ref(true)

    const id = route.params.id

    const getSheetSongInfo = async (id) => {
      const getInfo = route.params.type === '1' ? getOfficialSheetSongInfo : getPersonalSheetSongInfo
      const data = await getInfo(id)
      current.value = {
        name: data.name,
        song_code: data.song_code,
        py: data.py,
        song_type: data.song_type,
        sheet_type: data.sheet_type,
        speed: data.speed,
        picture_url: data.picture_url,
        evxml_file_url: data.evxml_file_url,
        evxml_file_md5: data.evxml_file_md5,
        evxml_file_size: data.evxml_file_size,
        evms_file_url: data.evms_file_url,
        evms_file_md5: data.evms_file_md5,
        evms_file_size: data.evms_file_size,
        ori_file_url: data.ori_file_url,
        ori_file_md5: data.ori_file_md5,
        ori_file_size: data.ori_file_size,
        acc_file_url: data.acc_file_url,
        acc_file_md5: data.acc_file_md5,
        acc_file_size: data.acc_file_size,
        acc_speed: data.acc_speed,
        book_id: data.book_id,
        book_section_id: data.book_section_id,
        bookName: data.book?.name,
        sectionName: data.section?.name,
        tags: data.tags.map(item => item.tag),
        extend_data: {
          multi_part_song: data?.extend_data?.multi_part_song ?? []
        }
      }
      initLoading.value = false
    }

    onMounted(async () => {
      await getSheetSongInfo(id)
    })

    const loading = ref(false)

    const handleBack = () => {
      history.go(-1)
    }

    const handleCreate = async (params, tags) => {
      try {
        delete params.book
        delete params.section
        delete params.tags
        delete params.bookName
        delete params.sectionName
        loading.value = true
        const editService = route.params.type === '1' ? editOfficialSheetSong : editPersonalSheetSong
        const setTagService = route.params.type === '1' ? setOfficialSheetSongTags : setPersonalSheetSongTags
        await editService(id, params)
        await setTagService(id, { tags })
        message.success('编辑成功')
        handleBack()
      } finally {
        loading.value = false
      }
    }
    return {
      current,
      initLoading,
      FormActionType,
      handleBack,
      handleCreate
    }
  }
}
</script>

<style>
</style>
