<template>
  <div>
    <a-upload
      name="song_file"
      :file-list="null"
      :multiple="false"
      accept=".mp3"
      :before-upload="handleBeforeUpload"
    >
      <a-button :disabled="loading">
        <UploadOutlined />{{ currentFile === null ? "选择文件" : "重选文件" }}
      </a-button>
    </a-upload>
    <span style="margin-top: 10px">{{fileName}}</span>
    <a-progress v-if="uploadPrecent > 0" :percent="uploadPrecent" :status="uploadStatus"></a-progress>
    <div class="upload_opt_btn">
      <a-button type="primary" @click="handleUpload" :loading="loading"
        >开始上传</a-button
      >
      <a-button
        class="clean_btn"
        type="primary"
        danger
        @click="handleCleanAll"
        :disabled="loading"
      >
        清空
      </a-button>
    </div>
  </div>
</template>

<script>
import { computed, ref, toRaw } from 'vue'
import { UploadOutlined } from '@ant-design/icons-vue'
import { uploadFileToOss } from '@/utils/oss'
import { message } from 'ant-design-vue'
export default {
  emits: ['onChange'],
  props: {
    formData: {
      type: Object,
      default: () => { return {} }
    },
    // 原唱还是伴唱
    type: {
      type: String,
      default: () => { return 'ori' }
    }
  },
  components: {
    UploadOutlined
  },
  setup (props, { emit }) {
    const formComputed = computed(() => props.formData)
    const currentFile = ref(null)
    const typeValue = ref(props.type)
    const loading = ref(false)
    const fileList = ref([])
    const uploadPrecent = ref(0)
    const uploadStatus = ref('normal')
    const fileName = ref('')

    const handleChangeUploadPrecent = (precent) => {
      uploadPrecent.value = precent
    }

    const updataFormData = (data) => {
      const { url, md5, size } = data
      const songInfo = {}
      if (typeValue.value === 'ori') {
        songInfo.ori_file_url = url
        songInfo.ori_file_md5 = md5
        songInfo.ori_file_size = size
      }
      if (typeValue.value === 'acc') {
        songInfo.acc_file_url = url
        songInfo.acc_file_md5 = md5
        songInfo.acc_file_size = size
      }
      const formInfo = { ...toRaw(formComputed.value), ...songInfo }
      emit('onChange', formInfo)
    }

    const handleUpload = async () => {
      if (currentFile.value) {
        try {
          loading.value = true
          uploadStatus.value = 'normal'
          const data = await uploadFileToOss(currentFile.value, true, handleChangeUploadPrecent)
          if (data.url.length > 200) {
            throw new Error('文件名称过长')
          }
          uploadStatus.value = 'success'
          loading.value = false
          message.success('上传成功')
          updataFormData(data)
        } catch (error) {
          console.log(error)
          uploadStatus.value = 'exception'
          message.error(error.message)
          loading.value = false
        }
      }
    }

    // 清空要上传的文件
    const handleCleanAll = () => {
      currentFile.value = null
      fileList.value = []
      fileName.value = ''
      uploadStatus.value = 'normal'
      uploadPrecent.value = 0
      let formInfo = { ...toRaw(formComputed.value) }
      if (typeValue.value === 'ori') {
        formInfo = {
          ...formInfo,
          ori_file_url: '',
          ori_file_md5: '',
          ori_file_size: 0
        }
      }
      if (typeValue.value === 'acc') {
        formInfo = {
          ...formInfo,
          acc_file_url: '',
          acc_file_md5: '',
          acc_file_size: 0
        }
      }
      emit('onChange', formInfo)
    }

    const handleBeforeUpload = file => {
      console.log(file)
      fileName.value = file.name
      uploadPrecent.value = 0
      uploadStatus.value = 'normal'
      currentFile.value = file
      return false
    }

    return {
      loading,
      currentFile,
      fileList,
      fileName,
      uploadPrecent,
      uploadStatus,
      handleUpload,
      handleCleanAll,
      handleBeforeUpload
    }
  }
}
</script>

<style>
.upload_opt_btn {
  margin-top: 16px;
}

.clean_btn {
  margin-left: 10px;
}
</style>
